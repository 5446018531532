import '@/styles/globals.css'

import { datadogRum } from '@datadog/browser-rum'
import { Analytics } from '@vercel/analytics/react'
import type { AppProps } from 'next/app'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'

const isProduction =
  process.env.NODE_ENV === 'production' &&
  process.env.VERCEL_ENV === 'production'

if (isProduction) {
  datadogRum.init({
    applicationId: `${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}`,
    clientToken: `${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}`,
    site: 'datadoghq.com',
    service: 'nopk-customer-agreements-tool-front-end',
    env: `${process.env.NODE_ENV}`,
    version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

const App = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session
}>) => {
  return (
    <SessionProvider session={pageProps.session} refetchInterval={0}>
      <Component {...pageProps} />
      <Analytics />
    </SessionProvider>
  )
}

export default App
